<template>
  <div :is="organizationData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="organizationData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.organization.organizationNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-organization-list' }">
          {{ $t("message.organization.organizationList") }}
        </b-link>
        {{ $t("message.organization.Organization") }}
      </div>
    </b-alert>

    <template v-if="organizationData">
      <div class="cardTableProject">
        <b-card>
          <h2 class="mb-2">
            {{ $t("message.Organization.viewOrganization") }}
          </h2>

          <b-row cols="12">
            <b-col md="4" class="mt-1">
              <b-form-group label-for="nameOpportunities">
                <template v-slot:label>
                  {{ $t("message.Organization.nameOfYourCompany") }}
                </template>
                <b-form-input
                  type="text"
                  v-model="organizationData.company"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- customers -->
            <b-col md="4" class="mt-1">
              <b-form-group label-for="costumer">
                <template v-slot:label>
                  {{ $t("message.Organization.emailOfCompany") }}
                </template>
                <b-form-input
                  readonly
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="organizationData.emailCompany"
                  type="email"
                />
              </b-form-group>
            </b-col>

            <b-col md="2" class="mt-1">
              <b-form-group label="CNPJ" label-for="startOpportunities">
                <b-form-input
                  readonly
                  v-model="organizationData.cnpj"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="2" class="mt-1">
              <b-form-group label-for="startOpportunities">
                <template v-slot:label>
                  {{ $t("message.Organization.phone") }}
                </template>
                <b-form-input readonly v-model="organizationData.phone" />
              </b-form-group>
            </b-col>

            <!-- end -->
            <b-col md="4">
              <b-form-group label-for="opportunityDate">
                <template v-slot:label>
                  {{ $t("message.Organization.fullResponsible") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.responsiblePerson"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.occupation") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.occupation"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.stateOfProcess") }}
                </template>
                <b-form-input readonly v-model="organizationData.status" />
              </b-form-group>
            </b-col>

            <!-- adress -->
          </b-row>
          <b-row cols="12">
            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.adress") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.adress"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.district") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.district"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.city") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.city"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.zipCode") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.zipCode"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="UF" label-for="value">
                <b-form-input
                  readonly
                  v-model="organizationData.uf"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br />
          <h5>Dados</h5>
          <br />

          <b-row>
            <b-col md="2">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("dataLimit") }}
                </template>
                <b-form-input readonly v-model="dataLimitMb" type="text" />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <br />
          <b-row>
            <b-col
              style="max-width: 200px; !important"
              v-for="item in filesData"
              :key="item.id"
            >
              <b-card
                text-variant="white"
                class="text-center"
                bg-variant="primary"
              >
                <feather-icon size="40" icon="FileIcon" />

                <div class="truncate">
                  <br />
                  <span>{{ item.name }}</span>
                </div>
                <br />
                <b-row>
                  <b-col md="12"
                    ><b-button
                      size="sm"
                      @click="downloadFile(item.id, item.name)"
                      v-if="restrictions('organization_file_download')"
                      ><feather-icon size="20" icon="DownloadCloudIcon"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" class="text-left">
              <b-button
                v-if="
                  organizationData.status != 'Aprovado' &&
                  organizationData.status != 'Rejeitado'
                "
                variant="warning"
                class="mr-1"
                @click="rejectOrganization()"
              >
                Enviar email e manter pendente
              </b-button>

              <b-button
                v-if="
                  organizationData.status != 'Aprovado' &&
                  organizationData.status != 'Rejeitado'
                "
                variant="success"
                class="mr-1"
                @click="generateOrganization()"
              >
                {{ $t("approve") }}
              </b-button>
            </b-col>

            <b-col md="6" class="text-right">
              <b-button
                variant="primary"
                :to="{ name: 'apps-organization-edit' }"
                v-if="restrictions('button_edit_organization')"
              >
                {{ $t("message.buttons.edit") }}
              </b-button>

              <b-button
                v-b-modal.modal-danger
                variant="outline-danger"
                class="ml-1 mr-2"
                v-if="restrictions('button_edit_organization')"
              >
                {{ $t("message.buttons.delete") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-danger"
        ok-variant="danger"
        hide-footer
        modal-class="modal-danger"
        :title="$t('delete')"
        centered
      >
        <b-card-text>
          {{ $t("message.Organization.youAreSure") }}
          <br />
          <b-button
            style="float: right"
            variant="outline-danger"
            class="btn-danger"
            @click="deleteOrganization()"
            :to="{ name: 'apps-organization-list' }"
          >
            {{ $t("message.Organization.yes") }}
          </b-button>
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end"></div>
      </b-modal>
    </template>

    <!-- Modals -->

    <b-modal
      id="approve-organization"
      modal-class="modal-success"
      centered
      size="lg"
      title="Aprovar organização"
      ok-only
      @ok="acceptOrganization()"
      ok-title="Aprovar e enviar"
      ok-variant="success"
    >
      <b-col>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <template v-slot:label>
                Por favor, informe o e-mail para o usuário root desta
                organização entrante: </template
              ><br />
              <b-input-group append="@brxmind.com.br">
                <b-form-input v-model="newOrganizationEmail" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>

    <b-modal
      id="rejected-organization"
      modal-class="modal-warning"
      centered
      size="lg"
      :title="$t('rejectOrganization')"
      ok-only
      @ok="sendRejectEmail()"
      :ok-title="$t('message.buttons.rejectAndSend')"
      ok-variant="warning"
    >
      <b-col>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <template v-slot:label>
                {{ $t("rejectOrganizationEmail") }} </template
              ><br />
              <quill-editor
                class="editor-layout"
                :options="editorOption"
                v-model="emailRejectMessage"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BModal,
  VBModal,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormTextarea,
  BFormDatepicker,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import AccessControl from "@core/utils/access-control";
import store from "@/store";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import organizationEmail from "@/@core/utils/email/emails/organization.js";
import organizationEmailApprove from "@/@core/utils/email/emails/organizationApprove.js";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormTextarea,
    vSelect,
    flatPickr,
    BFormDatepicker,
    BModal,
    quillEditor,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      userData: store.state.user.userData,
      paramData: store.state.user.paramData,
      dataLimitMb: "",
      organizationData: {},

      file: null,
      filesData: [],
      paramList: [],

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      emailRejectMessage: "",

      newOrganizationEmail: "",
    };
  },

  async created() {
    await this.getData();
    await this.getTaskFilesData();
    await this.getParam();
  },

  methods: {
    async getParam() {
      await axios.get(`${URL_API}param-login`, {}).then((response) => {
        this.paramList = response.data;
      });
    },

    async getTaskFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    sendRejectEmail() {
      axios
        .post(`${URL_API}sending-email-ordinary/${this.userData.tenant}`, {
          ownerRef: "Braxxy",
          emailFrom: "brxmind@braxxy.com.br",
          emailTo: this.organizationData.emailCompany,
          subject: "BRXMIND - [Pendências na documentação]",
          text: organizationEmail(
            this.emailRejectMessage,
            this.paramList.defaultUser
          ),
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t("message.toast.updatedB"),
              icon: "CheckIcon",
              variant: "success",
              text: `Email enviado com sucesso!`,
            },
          });
        });
    },

    async sendCreatedEmail() {
      var text = "";
      var shuffle =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 10; i++) {
        text += shuffle.charAt(Math.floor(Math.random() * shuffle.length));
      }

      var email = this.newOrganizationEmail + "@brxmind.com.br";
      var password = text;

      var user = {
        email: email,
        password: password,
        enabled: true,
        systemRole: "ROLE_USER",
        userGroup: "accessControlAdministrators",
        employeeId: "organization-root",
        tenant: this.organizationData.id,
        sessionTime: 50,
      };

      await axios({
        method: "post",
        url: `${URL_API}user/` + email,
        headers: getHeader(this.userData),
        data: user,
      });

      axios.post(`${URL_API}sending-email-ordinary/${this.userData.tenant}`, {
        ownerRef: "Braxxy",
        emailFrom: "brxmind@braxxy.com.br",
        emailTo: this.organizationData.emailCompany,
        subject: "BRXMIND - [Organização aprovada]",
        text: organizationEmailApprove(email, password),
      });

      //Create params and accessControl

      this.paramData.tenant = this.organizationData.id;
      delete this.paramData.id;

      await axios({
        method: "post",
        url: `${URL_API}param-organization`,
        headers: getHeader(this.userData),
        data: this.paramData,
      }).then(() => {});
    },

    rejectOrganization() {
      this.$root.$emit("bv::show::modal", "rejected-organization");
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}organization/${router.currentRoute.params.id}`,
        headers: getHeader(store.state.user.userData),
      })
        .then((response) => {
          this.organizationData = response.data;
          if (this.organizationData.dataLimit != null) {
            var sizeInMB = (
              this.organizationData.dataLimit /
              (1024 * 1024)
            ).toFixed(2);
            this.dataLimitMb = sizeInMB;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            organizationData.value = undefined;
          }
        });
    },

    generateOrganization() {
      this.$root.$emit("bv::show::modal", "approve-organization");
    },

    async acceptOrganization() {
      await axios({
        method: "put",
        url: `${URL_API}organization-status/${this.organizationData.id}`,
        headers: getHeader(this.userData),
        data: this.organizationData,
      }).then((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t("message.toast.updatedB"),
            icon: "CheckIcon",
            variant: "success",
            text: `O cadastro ${this.organizationData.company} foi editado com sucesso!`,
          },
        });

        this.sendCreatedEmail();
        this.$router.push({ name: "apps-organization-list" });
      });
    },

    deleteOrganization() {
      axios({
        method: "delete",
        url: `${URL_API}organization/${this.organizationData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O cadastro de organização foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            organizationData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.widthTable {
  width: 100%;
}

.widthTd {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .cardTableProject {
    width: 100%;
  }

  .rTable td {
    display: flex;
    flex-direction: column;
  }
  .rTableWidth {
    width: 100%;
  }

  .rButton {
    margin-top: 3rem;
  }
}

.editor-layout {
  height: 300px;
  padding-bottom: 40px;
}
</style>
